import React, { useState } from 'react';
import { useViewport } from 'react-viewport-hooks';
import Card from './../components/Card';
import TopIncomeInCalifornia from './articles/TopIncomeInCalifornia';
import TopIncomeInTexas from './articles/TopIncomeInTexas';
import TopSalesAugust2024 from './articles/TopSalesAugust2024';
import TopSalesSep2024 from './articles/TopSalesSep2024';
import TopCondoPriceIncreases from './articles/TopCondoPriceIncreases';
import TopCondoPriceIncreasesSep from './articles/TopCondoPriceIncreasesSep';
import TopSinglePriceIncreasesSep from './articles/TopSinglePriceIncreasesSep';
import TopSalesCaliforniaSep2024 from './articles/TopSalesCaliforniaSep2024';
import Grid from './../components/Grid';

const GridLayout = () => {

    const [index,setIndex] = useState(8);

    const handleInput = val => async(e) => {
        e.preventDefault();
        setIndex(val);
    }

    return (
        <Grid>
          <Grid.Row>
            <Grid.Column tiny={12} small={3} medium={3} large={3}>
              <ul>
                <li class="li-news" onClick={handleInput(8)}>Top Zip Codes with highest Single Family Median Price Increases<span class="li-new"> *New*</span></li>
                <li class="li-news" onClick={handleInput(7)}>Top Zip Codes By Sales in California</li>
                <li class="li-news" onClick={handleInput(6)}>Top Zip Codes with highest Condo Price Increases</li>
                <li class="li-news" onClick={handleInput(5)}>Top Zip Codes By Sales</li>
                <li class="li-news" onClick={handleInput(4)}>Top High Income Zip Codes In Texas</li>
                <li class="li-news" onClick={handleInput(3)}>Zip Codes with highest percent condo price increases</li>
                <li class="li-news" onClick={handleInput(2)}>Top High Income Zip Codes In California</li>
                <li class="li-news" onClick={handleInput(1)}>Top Zip Codes By Sales</li>
              </ul>
            </Grid.Column>
            <Grid.Column tiny={12} small={9} medium={9} large={9}>
              { (() => {
                if(index == 1)
                   return (<TopSalesAugust2024 />)
                else if(index == 2)
                   return (<TopIncomeInCalifornia/>)
                else if(index == 3)
                   return (<TopCondoPriceIncreases />)
                else if(index == 4)
                   return (<TopIncomeInTexas />)
                else if(index == 5)
                   return (<TopSalesSep2024 />)
                else if(index == 6)
                   return (<TopCondoPriceIncreasesSep />)
                else if(index == 7)
                   return (<TopSalesCaliforniaSep2024 />)
                else if(index == 8)
                   return (<TopSinglePriceIncreasesSep />)
                })()
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
    );
};

const ListLayout = () => {

    return (
        <Card>
          <Card>
            <details open>
              <summary class="li-news">Top Zip Codes With Highest Single Family Median Price increases<span class="li-new"> *New*</span></summary>
              <TopSinglePriceIncreasesSep />
            </details>
          </Card>
          <Card>
            <details open>
              <summary class="li-news">Top Zip Codes With Highest Sales in California<span class="li-new"> *New*</span></summary>
              <TopSalesCaliforniaSep2024 />
            </details>
          </Card>
          <Card>
            <details>
              <summary class="li-news">Top Zip Codes With Highest Condo Median Price increases</summary>
              <TopCondoPriceIncreasesSep />
            </details>
          </Card>
          <Card>
            <details>
            <summary class="li-news">Top Zip Codes With Highest Sales</summary>
            <TopSalesSep2024 />
            </details>
          </Card>
          <Card>
            <details>
              <summary class="li-news">Top Income Zip Codes in Texas</summary>
              <TopIncomeInTexas />
            </details>
          </Card>
          <Card>
            <details>
                <summary class="li-news">Top Zip Codes with highest percent increase in condo prices</summary>
                  <TopCondoPriceIncreases />
            </details>
          </Card>
          <Card>
            <details>
               <summary class="li-news">Top Income Zip Codes in California</summary>
               <TopIncomeInCalifornia />
            </details>
          </Card>
          <Card>
            <details>
               <summary class="li-news">Zip Codes with Highest Sales</summary>
               <TopSalesAugust2024 />
            </details>
          </Card>
        </Card>
    );
};

const News = () => {

        const { vw } = useViewport();
        console.log(vw);

        let layout = 'grid';
        if (vw < 768) {
            layout = 'list';
        }
        return (
            <div>
                {layout === 'grid' ?
                    <GridLayout /> :
                    <ListLayout />}
            </div>
        );
}

export default News;